import React, { useMemo } from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "../components/link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { slugPage, replaceHtml } from "../utility/utils"
import TextDecorator from '../components/text-decorator'


const NewsPage = ({ data }) => {
  const { pagina, news } = data;
  const { titolo, titoloInPagina, testo, immagineHero } = pagina;

  const testoMd = testo && testo.childMarkdownRemark.html;
  const testoHtml = useMemo(() => testoMd ? replaceHtml(testoMd) : null, [testoMd]);

  return (
    <Layout data={data}>
      <SEO page={pagina} data={data} />

      <div id="main" role="main">

        {/* Hero */}
        {immagineHero && (
          <div className="hero">
            <Img fluid={immagineHero.fluid} alt={titolo} objectFit="cover" objectPosition="center center"/>
          </div>
        )}

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={`${immagineHero ? 'prodotto has-hero' : 'prodotto'}`}>
                <div className="row">
                  <div className="col-12">
                    {/* Breadcrumb */}
                    <div id="breadcrumbs" className="breadcrumbs clearing" role="navigation" aria-label="breadcrumb">
                      <ol className="breadcrumbs__list" itemType="http://schema.org/BreadcrumbList" itemScope="itemscope">
                        <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement">
                          <div aria-level="1" itemProp="item">
                            <Link to="/" title="Homepage" className="breadcrumbs__link" itemProp="url"><span itemProp="name">Homepage</span></Link>
                          </div>
                          <meta content="1" itemProp="position" />
                        </li>
                        <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement">
                          <div aria-level={2} itemProp="item">
                            <span itemProp="name">{titolo}</span>
                          </div>
                          <meta content={2} itemProp="position" />
                        </li>
                      </ol>
                    </div>

                    {/* Titolo  */}
                    {titoloInPagina ? (
                      <h1 className="prodotto__title prodotto__title--titoloinpagina"><TextDecorator>{titoloInPagina}</TextDecorator></h1>
                    ) : (
                        <h1 className="prodotto__title">{titolo}</h1>
                      )}

                    {/* Testo */}
                    {testo && <div className="prodotto__text" dangerouslySetInnerHTML={{ __html: testoHtml }}></div>}
                  </div>
                </div>

                {/* Lista news */}
                {news && (
                  <div className="lista-news">
                    <div className="row">
                      {news.edges.map(edge => edge.node).map((item, i) => (
                        <div className="col-sm-6 col-lg-4" key={i}>
                          <div className="news">
                            {item.immagine && (
                              <Link to={`/${slugPage(pagina)}/${slugPage(item)}/`} className="news__link">
                                <Img fluid={item.immagine.fluid} className="news__img" />
                              </Link>
                            )}
                            <div className="news__text">
                              {item.data && (<div className="news__data">{item.data}</div>)}
                              <h2 className="news__title"><Link to={`/${slugPage(pagina)}/${slugPage(item)}/`} className="news__link">{item.titolo}</Link></h2>
                              {item.descrizione ? (
                                <div className="news__desc" dangerouslySetInnerHTML={{ __html: item.descrizione.childMarkdownRemark.html }} />
                              ) : (
                                <div>{item.testo.childMarkdownRemark.excerpt}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NewsPage;

export const query = graphql`
query NewsQuery{
  ...DefaultSeo
  ...HeaderFragment
  ...FooterRootFragment
  pagina: contentfulLayoutPaginaInterna(contentful_id: {eq: "52raFlPJTuseKq0bgDK7BB"}) {
    seo {
      title
      friendlyUrl
      description
      robots
      ogtitle
      ogdescription
      ogimage{
        fixed(width: 1920, quality: 100){
          src
        }
      }
    }
    titolo
    titoloInPagina
    immagineHero {
      fluid(maxWidth:1920) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    testo {
      childMarkdownRemark {
        html
      }
    }
  }
  news: allContentfulComunicazioniENews(sort: {fields: data, order: DESC}, limit: 12) {
    edges {
      node {
        immagine {
          fluid(maxWidth: 520) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        titolo
        seo {
          friendlyUrl
        }
        descrizione {
          childMarkdownRemark {
            html
          }
        }
        testo {
          childMarkdownRemark {
            html
            excerpt
          }
        }
        data(formatString: "DD/MM/YYYY", locale: "it_IT")
      }
    }
  }
}`;